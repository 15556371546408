import React, { Component } from 'react';
import './OverallData.css';


export class OverallData extends Component {
    static displayName = OverallData.name;

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true,
            sort: {
                column: 'total',
                direction: 'desc',
            },
        };
        this.onSort = this.onSort.bind(this)
    }

    componentDidMount() {
        this.populateOverallData();
    }

    onSort = (column) => (e) => {
        let direction = 'desc';

        if (this.state.sort.column && column === this.state.sort.column) {
            direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        }
        else if (column === 'name') {
            direction = 'asc';
        }
        else {
            direction = 'desc';
        }
        const sortedData = this.state.users.sort((a, b) => {
            if (column !== 'name') {
                const nameA = a[column]; // ignore upper and lowercase
                const nameB = b[column]; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else if (column === 'name') {
                const nameA = a[column].toUpperCase(); // ignore upper and lowercase
                const nameB = b[column].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else {
                return a.contractValue - b.contractValue;
            }
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            users: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    getUserColor(user) {
        if (user.total === 0)
            return 'white';
        if (user.standing === 1)
            return 'gold';
        if (user.standing === 2)
            return 'silver';
        if (user.standing === 3)
            return '#de9c71';
        else if (user.playoffs)
            return '#c2e5c2';
        else
            return 'white';
    };

    getUserName(user) {
        if (user.bye)
            return user.name
        else
            return user.name
    };

    renderOverallDataTable(users) {
        return (
            <div id="a">
                <table className='table table-striped' aria-labelledby="tabelLabel" id="overallTable">
                    <thead>
                        <tr style={{ cursor: 'pointer' }}>
                            <th onClick={this.onSort('standing')}>Rank</th>
                            <th onClick={this.onSort('name')}>Name</th>
                            <th onClick={this.onSort('total')}>Total</th>
                            <th onClick={this.onSort('week1')} style={{ whiteSpace: 'nowrap' }}>W1</th>
                            <th onClick={this.onSort('week2')} style={{ whiteSpace: 'nowrap' }}>W2</th>
                            <th onClick={this.onSort('week3')} style={{ whiteSpace: 'nowrap' }}>W3</th>
                            <th onClick={this.onSort('week4')} style={{ whiteSpace: 'nowrap' }}>W4</th>
                            <th onClick={this.onSort('week5')} style={{ whiteSpace: 'nowrap' }}>W5</th>
                            <th onClick={this.onSort('week6')} style={{ whiteSpace: 'nowrap' }}>W6</th>
                            <th onClick={this.onSort('week7')} style={{ whiteSpace: 'nowrap' }}>W7</th>
                            <th onClick={this.onSort('week8')} style={{ whiteSpace: 'nowrap' }}>W8</th>
                            <th onClick={this.onSort('week9')} style={{ whiteSpace: 'nowrap' }}>W9</th>
                            <th onClick={this.onSort('week10')} style={{ whiteSpace: 'nowrap' }}>W10</th>
                            <th onClick={this.onSort('week11')} style={{ whiteSpace: 'nowrap' }}>W11</th>
                            <th onClick={this.onSort('week12')} style={{ whiteSpace: 'nowrap' }}>W12</th>
                            <th onClick={this.onSort('week13')} style={{ whiteSpace: 'nowrap' }}>W13</th>
                            <th onClick={this.onSort('week14')} style={{ whiteSpace: 'nowrap' }}>W14</th>
                            <th onClick={this.onSort('week15')} style={{ whiteSpace: 'nowrap' }}>W15</th>
                            <th onClick={this.onSort('week16')} style={{ whiteSpace: 'nowrap' }}>W16</th>
                            <th onClick={this.onSort('week17')} style={{ whiteSpace: 'nowrap' }}>W17</th>
                            <th onClick={this.onSort('week18')} style={{ whiteSpace: 'nowrap' }}>W18</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user =>
                            <tr key={user.userid} data-item={user} style={{
                                background: this.getUserColor(user)}} >
                                <td style={{ fontWeight: this.state.sort.column === 'standing' ? 'bold' : '' }}>{user.standing}</td>
                                <td style={{ width: '175px', fontWeight: this.state.sort.column === 'name' ? 'bold' : '' }}>{this.getUserName(user)}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'total'? 'bold' : '' }}>{user.total}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week1' ? 'bold' : '' }}>{user.week1}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week2' ? 'bold' : '' }}>{user.week2}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week3' ? 'bold' : '' }}>{user.week3}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week4' ? 'bold' : '' }}>{user.week4}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week5' ? 'bold' : '' }}>{user.week5}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week6' ? 'bold' : '' }}>{user.week6}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week7' ? 'bold' : '' }}>{user.week7}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week8' ? 'bold' : '' }}>{user.week8}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week9' ? 'bold' : '' }}>{user.week9}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week10' ? 'bold' : '' }}>{user.week10}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week11' ? 'bold' : '' }}>{user.week11}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week12' ? 'bold' : '' }}>{user.week12}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week13' ? 'bold' : '' }}>{user.week13}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week14' ? 'bold' : '' }}>{user.week14}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week15' ? 'bold' : '' }}>{user.week15}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week16' ? 'bold' : '' }}>{user.week16}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week17' ? 'bold' : '' }}>{user.week17}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week18' ? 'bold' : '' }}>{user.week18}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderOverallDataTable(this.state.users);

        return (
            <div className="formContainer">
                <div className="form">
                <h1 id="tabelLabel">Overall Standings</h1>
                <p style={{ margin: '0px 0px 0px 0px' }}>Below are the overall standings for the season.</p>
                <p style={{ margin: '0px 0px 10px 0px' }}>Note that green and above denotes the users in the playoffs.</p>
                {contents}
                </div>
            </div>
        );
    }

    async populateOverallData() {
        const response = await fetch('users/alluserpoints');
        const data = await response.json();
        this.setState({ users: data, loading: false });
    }
}
