import React, { Component } from 'react';
import Select from 'react-select';
import { Button } from 'semantic-ui-react';
import { Container, Header } from 'semantic-ui-react';
import Moment from 'react-moment';
import { useAlert } from 'react-alert'
import './MyPicks.css';
import LoadingMask from 'react-loadingmask';
import "react-loadingmask/dist/react-loadingmask.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, fas, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { fab, faXbox } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

let options = [
    { value: 1, label: 'Week 1' },
    { value: 2, label: 'Week 2' },
    { value: 3, label: 'Week 3' },
    { value: 4, label: 'Week 4' },
    { value: 5, label: 'Week 5' },
    { value: 6, label: 'Week 6' },
    { value: 7, label: 'Week 7' },
    { value: 8, label: 'Week 8' },
    { value: 9, label: 'Week 9' },
    { value: 10, label: 'Week 10' },
    { value: 11, label: 'Week 11' },
    { value: 12, label: 'Week 12' },
    { value: 13, label: 'Week 13' },
    { value: 14, label: 'Week 14' },
    { value: 15, label: 'Week 15' },
    { value: 16, label: 'Week 16' },
    { value: 17, label: 'Week 17' },
    { value: 18, label: 'Week 18' }
];

const playoffOptions = [
    { value: 1, label: 'Week 1' },
    { value: 2, label: 'Week 2' },
    { value: 3, label: 'Week 3' },
    { value: 4, label: 'Week 4' },
    { value: 5, label: 'Week 5' },
    { value: 6, label: 'Week 6' },
    { value: 7, label: 'Week 7' },
    { value: 8, label: 'Week 8' },
    { value: 9, label: 'Week 9' },
    { value: 10, label: 'Week 10' },
    { value: 11, label: 'Week 11' },
    { value: 12, label: 'Week 12' },
    { value: 13, label: 'Week 13' },
    { value: 14, label: 'Week 14' },
    { value: 15, label: 'Week 15' },
    { value: 16, label: 'Week 16' },
    { value: 17, label: 'Week 17' },
    { value: 18, label: 'Week 18' },
    { value: 19, label: 'Wild Card' },
    { value: 20, label: 'Divisional' },
    { value: 21, label: 'Championship' },
    { value: 23, label: 'Super Bowl' }
];

const fontSize = 75;
const Football = <FontAwesomeIcon icon="football-ball" spin style={{ fontSize }} />;

export class MyPicks extends Component {
    static displayName = MyPicks.name;

    forwardLogin = () => {
        this.props.history.push({
            pathname: '/login'
            //    state: { user: this.state.currentUser }
        })
    }

    constructor(props) {
        super(props);
        let adminBool = false;
        let user;
        let userJSON = sessionStorage.getItem('user');

        if (userJSON != null) {
            user = JSON.parse(userJSON);
            if (user.name === "Michael Madden" || user.name === "Kendra Madden")
                adminBool = true;

            if (user.playoffs === "Y" || user.playoffs === "YES") {
                options = playoffOptions;
            }
        }

        this.state = {
            weeklyPicks: [],
            playoffPicks: [],
            loading: true,
            weekNo: 1,
            sort: {
                column: 'weeklyPoints',
                direction: 'desc',
            },
            confidencePoints: [],
            mnfScore: undefined,
            allGamesOver: true,
            submittingPicks: false,
            user: user,
            picksComplete: true,
            adminUser: adminBool
        };
    }

    async componentDidMount() {
        if (this.state.user == null) {
            this.forwardLogin();
        }
        else {
            await this.populateCurrentWeek();
            await this.populateGames();
            await this.populateUsersPicks();
            await this.populateUsersPlayoffPicks();
            await this.populateUserMNFScore();
        }

        //this.renderWeeklyPicksTable(this.state.games.events);
        //this.render();
    }

    async updateConfidencePointsDropdown() {
        let confidencePoints = [];
        confidencePoints.push({ value: 0, label: 'empty' });

        let missedGames = 0;

        if (this.state.weekNo <= 18) {
            for (let i = 1; i <= this.state.games.events.length; i++) {
                confidencePoints.push({ value: i, label: i });
            }
            for (let i = 0; i < this.state.games.events.length; i++) {
                let game = this.state.games.events[i];
                let stateGamePts = this.state[game.id + 'confidencepoints'];

                if (stateGamePts && stateGamePts.value != 0) {
                    let index = confidencePoints.findIndex(i => i.value === stateGamePts.value);
                    confidencePoints.splice(index, 1);
                }

                if (game.hasGameStarted) {

                    let winner = this.state[game.id];
                    let confPoints = this.state[game.id + 'confidencepoints'];

                    if (winner == null || confPoints == null || confPoints === 0) {
                        missedGames += 1;
                    }
                }
            }
        }
        else if (this.state.weekNo < 22) {
            for (let i = 1; i <= 12; i++) {
                confidencePoints.push({ value: i, label: i });
            }

            for (let i = 0; i < this.state.games.events.length; i++) {
                let game = this.state.games.events[i];
                let stateGamePts = this.state[game.id + 'confidencepoints'];

                if (stateGamePts && stateGamePts.value !== 0) {
                    let index = confidencePoints.findIndex(i => i.value === stateGamePts.value);
                    confidencePoints.splice(index, 1);
                }

                if (game.hasGameStarted) {

                    let winner = this.state[game.id];
                    let confPoints = this.state[game.id + 'confidencepoints'];

                    if (winner == null || confPoints == null || confPoints === 0) {
                        missedGames += 1;
                    }
                }
            }
            //Remove picks made from other playoff games
            for (let i = 0; i < this.state.userWeeklyPicks.length; i++) {
                let pick = this.state.userWeeklyPicks[i];
                if (pick.weekNumber !== this.state.weekNo && pick.confidencePoints !== null && pick.confidencePoints > 0) {
                    let index = confidencePoints.findIndex(i => i.value === pick.confidencePoints);
                    if (index >= 0) {
                        confidencePoints.splice(index, 1);
                    }
                }
            }
        }
        else {
            confidencePoints.push({ value: 13, label: 13 });
        }

        for (let i = 0; i < missedGames; i++) {
            let maxPoint = Math.max.apply(Math, confidencePoints.map(function (o) { return o.value; }));
            let index = confidencePoints.findIndex(i => i.value === maxPoint);
            confidencePoints.splice(index, 1);
        }

        this.checkCompleteness();

        this.setState({ confidencePoints: confidencePoints })
    }

    async updateConfidencePointsPlayoffs() {

    }

    async updateConfidencePoints(id, state, e) {
        //if (this.state.games.season.type > 2) {
        //    this.updateConfidencePointsPlayoffs();
        //}
        //else {
        //    await this.setState({ [id]: { value: e.value, label: e.label } });
        //    this.updateConfidencePointsDropdown();
        //}
        await this.setState({ [id]: { value: e.value, label: e.label } });
        this.updateConfidencePointsDropdown();
    };

    async winnerButtonHandler(e) {
        let button = e.target;

        if (button.attributes.winner != null) {
            if (button.attributes.winner.value === 'unset') {
                button.attributes.winner.value = 'set';

                let oppositeGame = document.getElementById(button.attributes.oppositeid.value);
                oppositeGame.attributes.winner.value = 'unset';
                await this.setState({ [button.attributes.gameid.value]: button.attributes.teamname.value });
            }
            else if (button.attributes.winner.value === 'set') {
                button.attributes.winner.value = 'unset';
                await this.setState({ [button.attributes.gameid.value]: null });
            }

        }
        else if (button.parentNode.attributes.winner != null) {
            if (button.parentNode.attributes.winner.value === 'unset') {
                button.parentNode.attributes.winner.value = 'set';

                let oppositeGame = document.getElementById(button.parentNode.attributes.oppositeid.value);
                oppositeGame.attributes.winner.value = 'unset';
                await this.setState({ [button.parentNode.attributes.gameid.value]: button.parentNode.attributes.teamname.value });
            }
            else if (button.parentNode.attributes.winner.value === 'set') {
                button.parentNode.attributes.winner.value = 'unset';
                await this.setState({ [button.parentNode.attributes.gameid.value]: null });
            }
        }
        else if (button.parentNode.parentNode.attributes.winner != null) {
            if (button.parentNode.parentNode.attributes.winner.value === 'unset') {
                button.parentNode.parentNode.attributes.winner.value = 'set';

                let oppositeGame = document.getElementById(button.parentNode.parentNode.attributes.oppositeid.value);
                oppositeGame.attributes.winner.value = 'unset';
                await this.setState({ [button.parentNode.parentNode.attributes.gameid.value]: button.parentNode.parentNode.attributes.teamname.value });
            }
            else if (button.parentNode.parentNode.attributes.winner.value === 'set') {
                button.parentNode.parentNode.attributes.winner.value = 'unset';
                await this.setState({ [button.parentNode.parentNode.attributes.gameid.value]: null });
            }
        }

        this.checkCompleteness();
    }

    renderWeeklyPicksTable(games) {
        return (
            <div id="myPicksTableDiv" style={{ fontSize: '12px', textAlign: 'center' }}>
                <table className='table table-striped' aria-labelledby="tabelLabel" id="myPicksTable">
                    <thead>
                        <tr style={{ cursor: 'pointer', fontSize: '16px' }}>
                            <th></th>
                            <th className="longDateLabel">Date</th>
                            <th style={{ display: 'none' }} className="shortDateLabel">Date</th>
                            <th>Teams</th>
                            <th className="spreadLabel">Spread</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody style={{ padding: '15,0,0,0' }}>
                        {games.map(game =>
                            <tr key={game.id} id={game.id + 'tr'} style={{ backgroundColor: 'white' }}>
                                <td className="iconLabel">
                                    <FontAwesomeIcon id={game.id + 'faIconCorrect'} icon={faCheckCircle} style={{ color: 'green', fontSize: '25px', display: 'none' }} />
                                    <FontAwesomeIcon id={game.id + 'faIconWrong'} icon={faTimesCircle} style={{ color: 'red', fontSize: '25px', display: 'none' }} />
                                </td>
                                <td className="longDateLabel" style={{ alignSelf: 'left' }}><Moment format="MM/DD/YYYY h:mm A">{game.gameDateTime}</Moment></td>
                                <td className="shortDateLabel" style={{ display: 'none', alignSelf: 'left' }}><Moment format="MM/DD">{game.gameDateTime}</Moment></td>
                                <td>
                                    <div className="ui buttons" ref={game.id + 'winner'}>
                                        <button id={game.id + 'awayTeamButton'} style={{ height: '85px' }} disabled={game.hasGameStarted} teamname={game.competitions[0].competitors[1].team.name} gameid={game.id} oppositeid={game.id + 'homeTeamButton'} className="ui button" winner={'unset'} winningteam={game.winningTeam} onClick={this.winnerButtonHandler.bind(this)}>
                                            <div style={{ display: 'inline-grid' }}>
                                                <img style={{ height: '50px', justifySelf: 'center' }} src={game.competitions[0].competitors[1].team.logo}></img>
                                                <label style={{ textTransform: 'uppercase' }} className="buttonLabel">{game.competitions[0].competitors[1].team.name}</label>
                                                <label style={{ fontWeight: 'normal' }}>{'(' + (game.competitions[0].competitors[1].records != null? game.competitions[0].competitors[1].records[0].summary : "0-0") + ')'}</label>
                                            </div>
                                        </button>
                                        <div style={{ alignSelf: 'center', width: '0px', zIndex: 3 }} className="or"></div>
                                        <button id={game.id + 'homeTeamButton'} style={{height: '85px'}} disabled={game.hasGameStarted} teamname={game.competitions[0].competitors[0].team.name} gameid={game.id} oppositeid={game.id + 'awayTeamButton'} className="ui button" winner={'unset'} winningteam={game.winningTeam} onClick={this.winnerButtonHandler.bind(this)}>
                                            <div style={{ display: 'inline-grid' }}>
                                                <img style={{ height: '50px', justifySelf: 'center' }} src={game.competitions[0].competitors[0].team.logo}></img>
                                                <label style={{textTransform: 'uppercase'}} className="buttonLabel">{game.competitions[0].competitors[0].team.name}</label>
                                                <label style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{'(' + (game.competitions[0].competitors[0].records != null? game.competitions[0].competitors[0].records[0].summary : "0-0") + ')'}</label>
                                            </div>
                                        </button>
                                    </div>
                                </td>
                                <td className="spreadLabel">{game.competitions[0].odds ? game.competitions[0].odds[0].details : 'NA'}</td>
                                <td className="confidencePointPicker">
                                    {/*    <input ref={game.id + 'confidencepoints'} id={game.id + 'confidencepointsid'} label={'Confidence Points'} isDisabled={game.mnfGame || game.hasGameStarted} onChange={e => this.updateConfidencePoints(game.id + 'confidencepoints', this.state, e)} value={this.state[game.id + 'confidencepoints']} />*/}
                                    <Select className='confidencePointDropdown' ref={game.id + 'confidencepoints'} isSearchable={false} id={game.id + 'confidencepointsid'} label={'Confidence Points'} isDisabled={(game.mnfGame && this.state.weekNo <= 18) || game.hasGameStarted} options={this.state.confidencePoints} onChange={e => this.updateConfidencePoints(game.id + 'confidencepoints', this.state, e)} value={this.state[game.id + 'confidencepoints']} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>

        );
    }

    async handleChange(e) {
        await this.setState({
            weekNo: e.value,
            loading: true,
            picksComplete: true
        });
        await this.populateGames();
        await this.populateUsersPicks();
        await this.populateUsersPlayoffPicks();
        await this.populateUserMNFScore();
        this.renderWeeklyPicksTable(this.state.games.events);
        this.render();
    }

    async checkCompleteness() {
        let showLabel = false;

        for (var i = 0; i < this.state.games.events.length; i++) {
            let game = this.state.games.events[i];

            let winner = this.state[game.id];
            let confPoints = this.state[game.id + 'confidencepoints'];

            if (winner === null || winner === undefined || confPoints === null || confPoints === undefined || confPoints.value === 0)
                showLabel = true;
        }

        if (showLabel === true) {
            this.setState({ picksComplete: false })
        }
        else {
            this.setState({ picksComplete: true })
        }
    }

    async submitPicks() {
        this.setState({ submittingPicks: true })

        let picks = [];
        for (var i = 0; i < this.state.games.events.length; i++) {
            let game = this.state.games.events[i];

            let winner = this.state[game.id];
            let confPoints = this.state[game.id + 'confidencepoints'];

            let pick = {
                PickID: 0,
                UpdateDate: (new Date().toLocaleString()),
                UserID: this.state.user.userID,
                WeekNumber: this.state.weekNo,
                Visitor: game.competitions[0].competitors[1].team.name,
                Home: game.competitions[0].competitors[0].team.name,
                Winner: winner,
                ConfidencePoints: confPoints.value
            }
            picks.push(pick);
        }
        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                UserID: this.state.user.userID,
                Week: this.state.weekNo,
                Picks: picks,
                MNFScore: this.state.mnfScore
            })
        }
        const response = await fetch('users/PostUserPicks', requestOptions);
        var message = await response.text();

        window.confirm(message);

        this.setState({ submittingPicks: false })
    }

    async autoFill(e) {
        let lockedGameValues = [];
        let availableOptions = [];

        for (var i = 1; i <= this.state.games.events.length; i++) {
            availableOptions.push(i);
        }
        let count = this.state.games.events.length;

        for (var i = 0; i < this.state.games.events.length; i++) {
            if (this.state.games.events[i].hasGameStarted) {
                let gameConfidencePoints = this.state.games.events[i].id + 'confidencepoints';

                if (this.state[gameConfidencePoints] !== undefined) {
                    availableOptions.splice(availableOptions.indexOf(this.state[gameConfidencePoints].value), 1);
                }

            }
        }
        for (var i = 0; i < this.state.games.events.length; i++) {

            let gameConfidencePoints = this.state.games.events[i].id + 'confidencepoints';
            let homeButton = document.getElementById(this.state.games.events[i].id + 'homeTeamButton');
            let awayButton = document.getElementById(this.state.games.events[i].id + 'awayTeamButton');

            if (!this.state.games.events[i].hasGameStarted) {
                if (!this.state.games.events[i].mnfGame) {
                    this.setState({ [gameConfidencePoints]: { value: availableOptions[0], label: availableOptions[0] } });
                }
                this.setState({ [this.state.games.events[i].id]: homeButton.attributes.teamname.value });
                homeButton.attributes.winner.value = 'set';
                awayButton.attributes.winner.value = 'unset';
                this.setState({})

                availableOptions.splice(0, 1);
            }
        }

        let confidencePoints = [];
        confidencePoints.push({ value: 0, label: 'empty' });
        this.setState({ confidencePoints: confidencePoints, picksComplete: true })
    }

    async autoFillFavorites(e) {
        let lockedGameValues = [];
        let availableOptions = [];

        for (var i = 1; i <= this.state.games.events.length; i++) {
            availableOptions.push(i);
        }

        let sortedGames = JSON.parse(JSON.stringify(this.state.games.events))

        sortedGames = sortedGames.sort((a, b) => parseFloat(a.bettingSpread) - parseFloat(b.bettingSpread)).reverse();

        for (var i = 0; i < sortedGames.length; i++) {
            if (this.state.games.events[i].hasGameStarted) {
                let gameConfidencePoints = sortedGames[i].id + 'confidencepoints';

                if (this.state[gameConfidencePoints] !== undefined) {
                    availableOptions.splice(availableOptions.indexOf(this.state[gameConfidencePoints].value), 1);
                }

            }
        }
        for (var i = 0; i < sortedGames.length; i++) {
            let game = sortedGames[i];

            let gameConfidencePoints = game.id + 'confidencepoints';
            let homeButton = document.getElementById(game.id + 'homeTeamButton');
            let awayButton = document.getElementById(game.id + 'awayTeamButton');

            if (!game.hasGameStarted) {
                if (!game.mnfGame) {
                    this.setState({ [gameConfidencePoints]: { value: availableOptions[0], label: availableOptions[0] } });
                    availableOptions.splice(0, 1);
                    this.setState({})
                }
                if (game.homeTeam === game.bettingTeam) {
                    this.setState({ [game.id]: homeButton.attributes.teamname.value });
                    homeButton.attributes.winner.value = 'set';
                    awayButton.attributes.winner.value = 'unset';
                }
                else {
                    this.setState({ [game.id]: awayButton.attributes.teamname.value });
                    homeButton.attributes.winner.value = 'unset';
                    awayButton.attributes.winner.value = 'set';
                }
            }
        }

        let confidencePoints = [];
        confidencePoints.push({ value: 0, label: 'empty' });
        this.setState({ confidencePoints: confidencePoints, picksComplete: true })
    }

    async autoClear(e) {
        for (var i = 0; i < this.state.games.events.length; i++) {

            let gameConfidencePoints = this.state.games.events[i].id + 'confidencepoints';
            let homeButton = document.getElementById(this.state.games.events[i].id + 'homeTeamButton');
            let awayButton = document.getElementById(this.state.games.events[i].id + 'awayTeamButton');

            if (!this.state.games.events[i].hasGameStarted) {
                if (!this.state.games.events[i].mnfGame) {
                    await this.setState({ [gameConfidencePoints]: { value: 0, label: 'empty' } });
                }
                await this.setState({ [this.state.games.events[i].id]: null });
                homeButton.attributes.winner.value = 'unset';
                awayButton.attributes.winner.value = 'unset';
            }
        }

        await this.updateConfidencePointsDropdown();
    }

    render() {
        let contents = (this.state.user === undefined) ?
            <p><em>Please login!</em></p> :
            this.state.loading
                ? <p><em>Loading...</em></p>
                : this.renderWeeklyPicksTable(this.state.games.events);

        return (
            <LoadingMask loading={this.state.submittingPicks} text={"Submitting Picks.."} indicator={Football} style={{ height: '100%' }}>
                <div className="formContainer">
                <div className="form" style={{ maxWidth: '750px' }}>
                        <div>
                            <div>
                                <h1 id="tabelLabel">MY PICKS</h1>
                                <div style={{ zIndex: 5, position: 'relative' }}>
                                    <Select id="allWeekNo" isSearchable={false} value={{ value: this.state.weekNo, label: 'Week ' + this.state.weekNo }} zIndex='6' onChange={this.handleChange.bind(this)} options={options} />
                                    <label style={{ float: 'left', color: 'red', fontWeight: 'bold', padding: '5px', fontSize: '16px', display: this.state.picksComplete? 'none':'block' }}>WARNING: PICKS INCOMPLETE!</label>
                                    <div style={{ minWidth: '175px', float: 'right', display: 'flex' }}>
                                        <button disabled={this.state.allGamesOver} className="btn btn-primary" style={{ margin: '5px', marginRight: '0px', float: 'right', display: this.state.adminUser && this.state.weekNo <= 18? 'block':'none' }} onClick={this.autoFillFavorites.bind(this)}>Auto Favorites</button>
                                        <button disabled={this.state.allGamesOver} className="btn btn-primary" style={{ margin: '5px', marginRight: '0px', float: 'right', display: this.state.weekNo <= 18? 'block': 'none'}} onClick={this.autoFill.bind(this)}>Auto Fill</button>
                                        <button disabled={this.state.allGamesOver} className="btn btn-primary" style={{ margin: '5px', float: 'right', display: this.state.weekNo <= 18 ? 'block' : 'none' }} onClick={this.autoClear.bind(this)}>Clear Picks</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {contents}
                            </div>
                            <div>
                                <div style={{ float: 'right', display: this.state.weekNo <= 18?'flex': 'none' }}>
                                    <label style={{ margin: '5px', fontSize:'16px' }} htmlFor="mnfInput">MNF Total Score</label>
                                    <input disabled={this.state.allGamesOver} style={{ width: '75px', fontSize:'16px' }} value={this.state.mnfScore} onChange={this.mnfChange.bind(this)} id="mnfInput" placeholder="MNF" />
                                </div>
                                <div className="form-group" style={{ float: 'center' }}>
                                    <button disabled={this.state.allGamesOver} className="btn btn-success" style={{ width: '100%', marginTop: '10px', height: '50px', fontSize:'16px' }} onClick={this.submitPicks.bind(this)}>SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingMask>

        );
    }

    mnfChange(e) {
        this.setState({ mnfScore: e.target.value });
    }

    async populateWeeklyPickData() {
        const response = await fetch('users/picks/' + this.state.weekNo);
        const data = await response.json();
        this.setState({ weeklyPicks: data, loading: false });
    }

    async populateCurrentWeek() {
        const response = await fetch('week/weekNo');
        const weekNo = await response.json();

        
        if (weekNo > 18 && this.state.user.playoffs === "Y" || this.state.user.playoffs === "YES") {
            this.setState({ weekNo: weekNo });
        }
        else if (weekNo > 18) {
            this.setState({ weekNo: 18 });
        }
        else {
            this.setState({ weekNo: weekNo });
        }

        var weekPicker = document.getElementById('allWeekNo');
        weekPicker.value = { value: weekNo, label: 'Week ' + weekNo };
    }

    async populateGames() {
        const response = await fetch('week/weekGames/' + this.state.weekNo);
        const data = await response.json();
        this.setState({ games: data, allGamesOver: true });

        let confidencePoints = [];
        confidencePoints.push({ value: 0, label: 0 });

        //if (data.season.type > 2) {
        //    for (var i = 0; i < this.state.games.events.length; i++) {
        //        if (!this.state.games.events[i].hasGameStarted) {
        //            this.setState({ allGamesOver: false })
        //        }
        //    }
        //}
        //else {
        //    for (var i = 0; i < this.state.games.events.length; i++) {
        //        confidencePoints.push({ value: i + 1, label: i + 1 });

        //        if (!this.state.games.events[i].mnfGame)
        //            this.setState({ [this.state.games.events[i].id + 'confidencepoints']: { value: 0, label: 'empty' } });
        //        else {
        //            this.setState({ [this.state.games.events[i].id + 'confidencepoints']: { value: this.state.games.events.length, label: this.state.games.events.length } });
        //        }

        //        if (!this.state.games.events[i].hasGameStarted) {
        //            this.setState({ allGamesOver: false })
        //        }
        //    }
        //}
        for (var i = 0; i < this.state.games.events.length; i++) {
            confidencePoints.push({ value: i + 1, label: i + 1 });

            if (!this.state.games.events[i].mnfGame || this.state.weekNo > 18)
                this.setState({ [this.state.games.events[i].id + 'confidencepoints']: { value: 0, label: 'empty' } });
            else {
                this.setState({ [this.state.games.events[i].id + 'confidencepoints']: { value: this.state.games.events.length, label: this.state.games.events.length } });
            }

            if (!this.state.games.events[i].hasGameStarted) {
                this.setState({ allGamesOver: false })
            }
        }
        this.setState({ confidencePoints: confidencePoints })
    }

    async populateUsersPlayoffPicks() {
        if (this.state.user !== undefined && this.state.user.userID !== undefined) {
            const response = await fetch('users/playoffPicksByUserID/' + this.state.user.userID);
            const data = await response.json();
            this.setState({ userPlayoffPicks: data});
        }
    }

    async populateUsersPicks() {
        if (this.state.user !== undefined && this.state.user.userID !== undefined) {

            let uri = 'users/picksByUserID/' + this.state.user.userID + '/' + + this.state.weekNo;
            if (this.state.weekNo > 18)
                uri = 'users/playoffPicksByUserID/' + this.state.user.userID;

            const response = await fetch(uri);
            const data = await response.json();
            this.setState({ userWeeklyPicks: data, loading: false });

            for (var i = 0; i < this.state.userWeeklyPicks.length; i++) {
                let userPick = this.state.userWeeklyPicks[i];

                let gameConfidencePoints = userPick.espnid + 'confidencepoints';
                let homeButton = document.getElementById(userPick.espnid + 'homeTeamButton');
                let awayButton = document.getElementById(userPick.espnid + 'awayTeamButton');
                let tableRow = document.getElementById(userPick.espnid + 'tr');
                let faiconCorrect = document.getElementById(userPick.espnid + 'faIconCorrect');
                let faiconWrong = document.getElementById(userPick.espnid + 'faIconWrong');

                this.setState({ [gameConfidencePoints]: { value: userPick.confidencePoints, label: userPick.confidencePoints !== 0 ? userPick.confidencePoints : 'empty' } });
                this.setState({ [userPick.espnid]: userPick.winner });

                if (userPick !== null && userPick !== undefined) {
                    if (userPick.winner === userPick.home && homeButton !== null && homeButton !== undefined) {
                        homeButton.attributes.winner.value = 'set';
                        if (homeButton.attributes.winningteam != null) {
                            if (homeButton.attributes.winningteam.value === homeButton.attributes.teamname.value) {
                                //homeButton.attributes.winner.value = true;
                                faiconCorrect.style.display = 'block';
                                //tableRow.style.backgroundColor = "rgb(153 186 159 / 60%)";
                            }
                            else {
                                //homeButton.attributes.winner.value = false;
                                faiconWrong.style.display = 'block';
                                //tableRow.style.backgroundColor = "rgb(203 56 56 / 50%)";
                            }
                        }

                    }
                    else if (userPick.winner === userPick.visitor && awayButton !== null && awayButton !== undefined) {
                        awayButton.attributes.winner.value = 'set';
                        if (awayButton.attributes.winningteam != null) {
                            if (awayButton.attributes.winningteam.value === awayButton.attributes.teamname.value) {
                                //awayButton.attributes.winner.value = true;
                                faiconCorrect.style.display = 'block';
                                //tableRow.style.backgroundColor = "rgb(153 186 159 / 60%)";
                            }
                            else {
                                //awayButton.attributes.winner.value = false;
                                faiconWrong.style.display = 'block';
                                //tableRow.style.backgroundColor = "rgb(203 56 56 / 50%)";
                            }
                        }

                    }
                    else if (userPick.winner === "" && userPick.hasGameStarted) {
                        faiconWrong.style.display = 'block';
                        //tableRow.style.backgroundColor = "rgb(203 56 56 / 50%)";
                    }
                }
                //else {
                //    if (homeButton.attributes.winningteam != null || awayButton.attributes.winningTeam != null) {
                //        tableRow.style.backgroundColor = "rgb(203 56 56 / 50%)";
                //    }
                //}

            }

            

            this.updateConfidencePointsDropdown();
        }

    }

    async populateUserMNFScore() {
        if (this.state.user !== undefined && this.state.user.userID !== undefined) {
            const response = await fetch('users/mnfByUserID/' + this.state.user.userID + '/' + + this.state.weekNo);
            const data = await response.json();
            this.setState({ mnfScore: data.points, loading: false });
        }

    }
}
