import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <div style={{ overflow: 'auto', height: 'calc(100vh - 50px)' }}>
            <div style={{margin: '15px'}}> 
                <p style={{ margin: '0px 0px 2px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '24px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}><strong>Welcome to the 6th year of&nbsp;<span className="il">Madden</span> <span className="il">Pick</span>'<span className="il">ems</span>!</strong></p>
                <p style={{ margin: '0px 0px 2px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '20px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"RULES"}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"This is a straight up pick'em league. The spread is included when making picks for informational purposes only."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"Last game of the week is locked as the highest point game of the week (Example - Monday night game is the 16 point game for everyone, week 1)."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"You can update your picks up until kick-off for each game (Once noon games have started those games will be locked, however, you can still update games that haven't started yet)"}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"If you forget to make your picks prior to the first game starting each week, your highest weight for the week will be deducted."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"You're competing for weekly high scores, but your total season points will also be tracked."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"In the event of a tie on any given week, it'll be broken by a total score prediction for the last game of the week (typically MNF). If it's still a tie, weekly earnings will be split."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"It's a pretty straight forward pick'em league outside of the last game of the week being the high point game each week. It's a matter of trying to find a balance between winning weekly pot, while also trying to stay competitive in the overall standings and make the playoffs."}</p>
                <br />
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '20px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"PLAYOFFS"}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"Top 37.5% (Rounded up) Regular Season Point Leaders will make the playoffs. (Coincide with acutal NFL Playoffs)"}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"Each playoff week you pick a winner for every game, and place a weight on each game from 1 to 12."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"Once you use a weight on any game you can't use it again in a subsequent week; that is, you'll spread the 1 through 12 weights across all games throughout the playoffs (there are 13 total playoff games including the Super Bowl)."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"Similar to how the final game of a regular season week is locked as your highest pick, the Super Bowl will be locked as your 13 point pick."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"In the event you forget to enter a pick prior to the game starting, it will result in a forfeiture of your highest remaining weight."}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"The player with the most points after the Super Bowl wins"}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"Prizes for the top 3 scores in the playoffs will be awarded for finishing first, second, and third."}</p>
                <br />

                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '20px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{"EARNINGS"}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>Weekly winner during season: 2.5%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>1st Place Total Regular Season: 14%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>2nd Place Total Regular Season: 9%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>3rd Place Total Regular Season: 5%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>1st Place Playoffs: 11%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>2nd Place Playoffs: 8%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '16px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>3rd Place Playoffs: 4%</strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong><br /></strong>}</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>{<strong>Note that 4% will be dedicated toward the cost of maintaining the website</strong>}</p>
                <p style={{ margin: '0px', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"', minHeight: '15px' }}><br /></p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>I'll collect money through PayPal/Venmo. I'll also distribute winnings this way at the end of the season.</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}>Feel free to contact me personally through text at 815-751-3230.</p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"', color: 'rgb(220, 161, 13)' }}><span style={{ color: 'rgb(0, 0, 0)' }}><strong>PayPal -&nbsp;</strong><a href="mailto:michael.madden23@gmail.com" style={{ color: 'rgb(17, 85, 204)' }} target="_blank"><strong>michael.madden23@gmail.com</strong></a></span></p>
                <p style={{ margin: '0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '14px', lineHeight: 'normal', fontFamily: '"Helvetica Neue"' }}><strong>Venmo - michael-madden23</strong></p>
                <div className="yj6qo"><br /></div>
                <div className="adL"><br style={{ color: 'rgb(34, 34, 34)', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 'small', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'start', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', backgroundColor: 'rgb(255, 255, 255)', textDecorationThickness: 'initial', textDecorationStyle: 'initial', textDecorationColor: 'initial' }} /></div>
            </div>
      </div>
    );
  }
}
