import React, { Component } from 'react';
import './Login.css';
import LoadingMask from 'react-loadingmask';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { red } from '@material-ui/core/colors';

library.add(fas, fab);

//function checkUser() {
//    const [count, setCount] = React.useState(0)

//    React.useEffect(() => {
//        const parsedUser = Number(localStorage.getItem("user") || 0)
//        this.state.user = parsedUser;
//    }, [])
//}
const displayMap = {
    false: 'none',
    true: 'block'
};
const fontSize = 75;
const Football = <FontAwesomeIcon icon="football-ball" spin style={{ fontSize }} />;


export class Login extends Component {
    static displayName = Login.name;
    static currentUser = null;

    forwardMyPicks = () => {
        this.props.history.push({
            pathname: '/my-picks'
            //    state: { user: this.state.currentUser }
        })
    }

    constructor(props) {
        super(props);
        let currentUser;
        let userJSON = sessionStorage.getItem('user');
        if (userJSON != null) {
            currentUser = JSON.parse(userJSON);
            if (currentUser.email === "michael.madden23@gmail.com") {
                this.populateUserData();
            }
        }
        this.state = {
            message: "test",
            email: null,
            password: null,
            currentUser: currentUser,
            passwordLabel: 'Password',
            submitLabel: 'Login',
            resetPasswordLabel: 'Forgot Password?',
            authControlsDisplay: false,
            authCode: null,
            authCodeSending: false,
            enteredAuthCode: null,
            users: []
        };

    }

    async updateEmail(value) {
        await this.setState({
            email: value
        });
    }

    async updatePassword(value) {
        await this.setState({
            password: value
        });
    }

    async updateAuthCode(value) {
        await this.setState({
            enteredAuthCode: value
        });
    }

    async updatePaid(user) {
        try {
            const requestOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Email: user.email
                })
            }
            let response = await fetch('users/UpdateUser', requestOptions);
            user = await response.text();
            this.populateUserData();
            this.render();
        }
        catch {

        }

    }

    renderLoginForm() {
        return (
            <LoadingMask loading={this.state.authCodeSending} text={"Submitting Picks.."} indicator={Football} style={{ height: '100%' }}>
                <div class="row" style={{ width: '100%', margin: '10px', justifyContent: 'center' }}>
                    <form style={{
                        width: '100%',
                        justifyContent: 'center', alignItems: 'center', maxWidth: '400px'
                    }}>
                        <div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input onChange={e => this.updateEmail(e.target.value)} type="email" name="email" class="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Enter email" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">{this.state.passwordLabel}</label>
                                <input onChange={e => this.updatePassword(e.target.value)} type="password" name="password" class="form-control" id="passwordInput" placeholder="Password" />
                            </div>
                            <div style={{ display: this.state.authControlsDisplay ? 'block' : 'none' }} id="authorizationDIV" class="form-group">
                                <label for="inputAuthorization">Authorization Code</label>
                                <input onChange={e => this.updateAuthCode(e.target.value)} name="inputAuthorization" class="form-control" id="inputAuthorization" placeholder="Authorization Code sent to Email" />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <label style={{ font: 'faItalic', cursor: 'pointer', fontSize: '16px' }} onClick={this.changePassword.bind(this)}>{this.state.resetPasswordLabel}</label>
                        </div>
                        <div>
                            <button style={{ width: '90%' }} onClick={this.tryLogin.bind(this)} class="btn btn-primary">{this.state.submitLabel}</button>
                        </div>
                    </form>
                </div >
            </LoadingMask>
        );
    }

    renderUserPaidTable() {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel" id="overallTable">
                <thead>
                    <tr style={{ cursor: 'pointer' }}>
                        <th>Name</th>
                        <th>Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.users.map(user =>
                        <tr key={user.userid} data-item={user} onClick={() => this.updatePaid(user)} style={{
                            background: (user.paid === "Y" ? 'darkseagreen' : 'salmon')
                        }} >
                            <td>{user.name}</td>
                            <td>{user.paid}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    renderUserForm() {
        let contents = "";

        if (this.state.currentUser.email === "michael.madden23@gmail.com") {
            contents = this.renderUserPaidTable();
        }

        return (
            <div className="formContainer">
                <div className="form">
                    <p> <em>Welcome {this.state.currentUser.name}!</em></p>
                    {contents}
                </div>
            </div>
        );
    }

    async changePassword() {
        if (this.state.passwordLabel !== 'New Password') {
            await this.setState({
                passwordLabel: 'New Password',
                submitLabel: 'Submit',
                resetPasswordLabel: 'Send Authorization Code',
                authControlsDisplay: true
            });
        }
        else {
            const requestOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Email: this.state.email
                })
            }
            try {
                this.setState({ authCodeSending: true });
                const response = await fetch('users/SendAuthCodeToUser', requestOptions);
                this.state.authCode = await response.text();
                this.setState({ authCodeSending: false });
                window.confirm("Authorization code sent to email");
            }
            catch {
                window.confirm("Error sending authorization code to email entered.");
            }
        }
    }

    async handleChange() {
        this.render();
    }

    render() {
        let contents = this.state.currentUser === undefined ? this.renderLoginForm() : this.renderUserForm()
        return (
            <div>
                {contents}
            </div>
        );
    }

    async tryLogin(e) {
        e.preventDefault();

        if (this.state.submitLabel === 'Submit') {
            if (this.state.authCode !== this.state.enteredAuthCode) {
                window.confirm("Authorization Code does not match.");
            }
            else if (this.state.password === null || this.state.password === "") {
                window.confirm("Please enter a password to update.");
            }
            else {
                const requestOptions = {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Email: this.state.email,
                        Password: this.state.password
                    })
                }
                try {
                    const response = await fetch('users/ChangeUserPassword', requestOptions);
                    const user = await response.json();
                    this.setState({ currentUser: user });

                    sessionStorage.setItem('user', JSON.stringify(user))
                    //React.useEffect(() => {
                    //    localStorage.setItem("user", user)
                    //}, [user])

                    this.handleChange();

                    this.forwardMyPicks();
                }
                catch {
                    var message = "Error logging in. Please verify email and password.";

                    window.confirm(message);
                }
            }
        }
        else {
            const requestOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Email: this.state.email,
                    Password: this.state.password
                })
            }
            try {
                const response = await fetch('users/LoginUser', requestOptions);
                const user = await response.json();
                this.setState({ currentUser: user });

                sessionStorage.setItem('user', JSON.stringify(user))
                //React.useEffect(() => {
                //    localStorage.setItem("user", user)
                //}, [user])

                this.handleChange();

                this.forwardMyPicks();
            }
            catch {
                var message = "Error logging in. Please verify email and password.";

                window.confirm(message);
            }
        }
    }

    async populateUserData() {
        const response = await fetch('users/allUsers');
        const data = await response.json();
        this.setState({ users: data });
    }
}
