import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { WeeklyData } from './components/WeeklyData';
import { MyPicks } from './components/MyPicks';
import { OverallData } from './components/OverallData';
import { Playoffs } from './components/Playoffs';
import { Login } from './components/Login';
import { UserProvider } from './contexts/UserProvider';


import 'semantic-ui-css/semantic.min.css';
import './custom.css'


export default class App extends Component {
    static displayName = App.name;
   
  render () {
      return (
            <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/weekly-data' component={WeeklyData} />
            <Route path='/my-picks' component={MyPicks} />
            <Route path='/overall-data' component={OverallData} />
            <Route path='/playoffs' component={Playoffs} />
            <Route path='/login' component={Login} />
            </Layout>

    );
  }
}