import React, { Component } from 'react';
import './Playoffs.css';


export class Playoffs extends Component {
    static displayName = Playoffs.name;

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true,
            sort: {
                column: 'playoffTotal',
                direction: 'desc',
            },
        };
        this.onSort = this.onSort.bind(this)
    }

    componentDidMount() {
        this.populatePlayoffData();
    }

    onSort = (column) => (e) => {
        let direction = 'desc';

        if (this.state.sort.column && column === this.state.sort.column) {
            direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        }
        else if (column === 'name') {
            direction = 'asc';
        }
        else {
            direction = 'desc';
        }
        const sortedData = this.state.users.sort((a, b) => {
            if (column !== 'name') {
                const nameA = a[column]; // ignore upper and lowercase
                const nameB = b[column]; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else if (column === 'name') {
                const nameA = a[column].toUpperCase(); // ignore upper and lowercase
                const nameB = b[column].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else {
                return a.contractValue - b.contractValue;
            }
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            users: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    getUserColor(user) {
        if (user.playoffTotal === 0)
            return 'white';
        if (user.playoffStanding === 1)
            return 'gold';
        if (user.playoffStanding === 2)
            return 'silver';
        if (user.playoffStanding === 3)
            return '#de9c71';
        else
            return 'white';
    };

    getUserName(user) {
        if (user.bye)
            return user.name
        else
            return user.name
    };

    renderPlayoffOverallDataTable(users) {
        return (
            <div id="a">
                <table className='table table-striped' aria-labelledby="tabelLabel" id="playoffTable">
                    <thead>
                        <tr style={{ cursor: 'pointer' }}>
                            <th onClick={this.onSort('playoffStanding')}>Rank</th>
                            <th onClick={this.onSort('name')}>Name</th>
                            <th onClick={this.onSort('playoffTotal')}>Total</th>
                            <th onClick={this.onSort('playoffPossiblePoints')}>Possible Points</th>
                            <th onClick={this.onSort('week19')} style={{ whiteSpace: 'nowrap' }}>Wild Card</th>
                            <th onClick={this.onSort('week20')} style={{ whiteSpace: 'nowrap' }}>Divisional</th>
                            <th onClick={this.onSort('week21')} style={{ whiteSpace: 'nowrap' }}>Championship</th>
                            <th onClick={this.onSort('week23')} style={{ whiteSpace: 'nowrap' }}>Super Bowl</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user =>
                            <tr key={user.userid} data-item={user} style={{
                                display: user.playoffs === true ? 'table-row' : 'none',
                                background: this.getUserColor(user)
                            }} >
                                <td style={{ fontWeight: this.state.sort.column === 'playoffStanding' ? 'bold' : '' }}>{user.playoffStanding}</td>
                                <td style={{ width: '175px', fontWeight: this.state.sort.column === 'name' ? 'bold' : '' }}>{this.getUserName(user)}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'total' ? 'bold' : '' }}>{user.playoffTotal}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'playoffPossiblePoints' ? 'bold' : '' }}>{user.playoffPossiblePoints}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week1' ? 'bold' : '' }}>{user.week19}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week2' ? 'bold' : '' }}>{user.week20}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week3' ? 'bold' : '' }}>{user.week21}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'week4' ? 'bold' : '' }}>{user.week23}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            :  this.renderPlayoffOverallDataTable(this.state.users);

        return (
            <div className="formContainer">
                <div className="form">
                <h1 id="tabelLabel">Playoff Standings</h1>
                <p style={{ margin: '0px 0px 0px 0px' }}>Below are the Playoff Standings.</p>
                {contents}
                </div>
            </div>
        );
    }

    async populatePlayoffData() {
        const response = await fetch('users/alluserpointsplayoffs');
        const data = await response.json();
        this.setState({ users: data, loading: false });
    }
}
