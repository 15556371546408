import React, { Component } from 'react';
import Select from 'react-select';
import './WeeklyData.css';
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const options = [
    { value: 1, label: 'Week 1' },
    { value: 2, label: 'Week 2' },
    { value: 3, label: 'Week 3' },
    { value: 4, label: 'Week 4' },
    { value: 5, label: 'Week 5' },
    { value: 6, label: 'Week 6' },
    { value: 7, label: 'Week 7' },
    { value: 8, label: 'Week 8' },
    { value: 9, label: 'Week 9' },
    { value: 10, label: 'Week 10' },
    { value: 11, label: 'Week 11' },
    { value: 12, label: 'Week 12' },
    { value: 13, label: 'Week 13' },
    { value: 14, label: 'Week 14' },
    { value: 15, label: 'Week 15' },
    { value: 16, label: 'Week 16' },
    { value: 17, label: 'Week 17' },
    { value: 18, label: 'Week 18' },
    { value: 19, label: 'Wild Card' },
    { value: 20, label: 'Divisional' },
    { value: 21, label: 'Championship' },
    { value: 23, label: 'Super Bowl' }];

export class WeeklyData extends Component {
    static displayName = WeeklyData.name;

    constructor(props) {
        super(props);
        this.state = {
            weeklyPicks: [],
            loading: true,
            weekNo: 1,
            sort: {
                column: 'weeklyPoints',
                direction: 'desc',
            }
        };
        this.onSort = this.onSort.bind(this)
    }

    async componentDidMount() {
        await this.populateCurrentWeek();
        await this.populateAbbreviations();
        await this.populateWeeklyWinner();
        this.populateWeeklyPickData();
    }

    onSort = (column) => (e) => {
        let direction = 'desc';

        if (this.state.sort.column && column === this.state.sort.column) {
            direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        }
        else if (column === 'name') {
            direction = 'asc';
        }
        else {
            direction = 'desc';
        }
        const sortedData = this.state.weeklyPicks.sort((a, b) => {
            if (column !== 'name' && column !== 'weeklyPoints' && column !== 'possiblePoints' && column !== 'mnfScore') {
                const nameA = a[column]['winner'].toUpperCase(); // ignore upper and lowercase
                const nameB = b[column]['winner'].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA === nameB) {
                    const pointA = a[column]['confidencePoints']; // ignore upper and lowercase
                    const pointB = b[column]['confidencePoints']; // ignore upper and lowercase
                    if (pointA < pointB) {
                        return -1;
                    }
                    if (pointA > pointB) {
                        return 1;
                    }
                }

                // names must be equal
                return 0;
            }
            else if (column === 'weeklyPoints' || column === 'possiblePoints') {
                const nameA = a['user'][column]; // ignore upper and lowercase
                const nameB = b['user'][column]; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else if (column === 'mnfScore') {
                const nameA = a[column]; // ignore upper and lowercase
                const nameB = b[column]; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else if (column === 'name') {
                const nameA = a['user'][column].toUpperCase(); // ignore upper and lowercase
                const nameB = b['user'][column].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else {
                return a.contractValue - b.contractValue;
            }
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            weeklyPicks: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    renderWeeklyPicksTable(weeklyPicks) {
        return (
            <div id="weeklyTableDiv" className="table-container">
                <table className='table table-striped' aria-labelledby="tabelLabel" id="weeklyTable">
                    <thead>
                        <tr style={{ cursor: 'pointer' }}>
                            <th rowSpan='3'
                                className="sticky-column"
                                style={{
                                    fontWeight: this.state.sort.column === 'name' ? 'bold' : '',
                                    wordWrap: 'break-word',  // Allow wrapping of long text
                                    whiteSpace: 'normal'    // Allow wrapping in header
                                }}
                                onClick={this.onSort('name')}
                            >
                                Name
                            </th>
                            <th rowSpan='3'
                                style={{
                                    fontWeight: this.state.sort.column === 'weeklyPoints' ? 'bold' : '',
                                    maxWidth: '80px',  // Control width of Total column
                                    wordWrap: 'break-word',
                                    whiteSpace: 'normal'
                                }}
                                onClick={this.onSort('weeklyPoints')}
                            >
                                Total
                            </th>
                            <th rowSpan='3'
                                style={{
                                    fontWeight: this.state.sort.column === 'possiblePoints' ? 'bold' : '',
                                    maxWidth: '80px',  // Control width of Possible column
                                    wordWrap: 'break-word',
                                    whiteSpace: 'normal'
                                }}
                                onClick={this.onSort('possiblePoints')}
                            >
                                Possible
                            </th>
                            {/* Additional columns for each game */}
                            {this.state.abbreviations.map((abbr, index) => (
                                <th
                                    rowSpan={abbr.score === null? 3 :1}
                                    key={`game-${index}`}
                                    style={{
                                        fontWeight: this.state.sort.column === `game${index + 1}` ? 'bold' : '',
                                        //minWidth: '75px',
                                        wordWrap: 'normal',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        fontSize: '11px',
                                        lineHeight: '.75'// Center the text for better readability
                                    }}
                                    onClick={this.onSort(`game${index + 1}`)}
                                >
                                    {abbr.game}
                                </th>
                            ))}
                            <th rowSpan="3"
                                style={{
                                    fontWeight: this.state.sort.column === 'mnfScore' ? 'bold' : '',
                                    maxWidth: '80px',  // Control width of MNF column
                                    wordWrap: 'break-word',
                                    whiteSpace: 'normal'
                                }}
                                onClick={this.onSort('mnfScore')}
                            >
                                MNF
                            </th>
                        </tr>
                        <tr style={{ border: 'none', lineHeight: '.75' }}>
                            {/* Additional columns for each game */}
                            {this.state.abbreviations.map((abbr, index) => (
                                (abbr.score !== null ? <th
                                    rowSpan={abbr.score === null ? 0 : 1}
                                    key={`game-${index}`}
                                    style={{
                                        fontWeight: this.state.sort.column === `game${index + 1}` ? 'bold' : '',
                                        //minWidth: '75px',
                                        wordWrap: 'normal',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        fontSize: '11px'// Center the text for better readability
                                    }}
                                    onClick={this.onSort(`game${index + 1}`)}
                                >
                                    {abbr.score}
                                </th>:"")
                            ))}
                        </tr>
                        <tr style={{ border: 'none', lineHeight: '.75' }}>
                            {/* Additional columns for each game */}
                            {this.state.abbreviations.map((abbr, index) => (
                                (abbr.percentage !== null ? < th
                                    rowSpan={abbr.percentage === null ? 0 : 1}
                                    key={`game-${index}`}
                                    style={{
                                        fontWeight: this.state.sort.column === `game${index + 1}` ? 'bold' : '',
                                        //minWidth: '75px',
                                        wordWrap: 'normal',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        fontSize: '11px'// Center the text for better readability
                                    }}
                                    onClick={this.onSort(`game${index + 1}`)}
                                >
                                    {abbr.percentage !== null ? abbr.percentage : ""}
                                </th>:"")
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {weeklyPicks.map((weeklyPick) => (
                            <tr key={weeklyPick.user.userID}>
                                <td
                                    className="sticky-column"
                                    style={{
                                        textAlign: 'left',
                                        padding: '5px',
                                        fontWeight: this.state.sort.column === 'name' ? 'bold' : '',
                                        whiteSpace: 'nowrap',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {(weeklyPick.user.userID === this.state.weeklyWinner.userID ? <FontAwesomeIcon icon={faTrophy} style={{ color: 'gold', fontSize: '20px', display: 'block', paddingRight: '5px' }} /> : "")}
                                        {weeklyPick.user.name}
                                    </div>
                                </td>
                                <td
                                    style={{
                                        fontWeight: this.state.sort.column === 'weeklyPoints' ? 'bold' : '',
                                        maxWidth: '80px',  // Control width of Total column
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {weeklyPick.user.weeklyPoints}
                                </td>
                                <td
                                    style={{
                                        fontWeight: this.state.sort.column === 'possiblePoints' ? 'bold' : '',
                                        maxWidth: '80px',  // Control width of Possible column
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {weeklyPick.user.possiblePoints}
                                </td>
                                {/* Game columns */}
                                {this.state.abbreviations.map((abbreviation, index) => (
                                    <td
                                        key={index}
                                        style={{
                                            color: weeklyPick[`game${index + 1}`]?.isGameOver
                                                ? (weeklyPick[`game${index + 1}`].isCorrect ? 'green' : 'red')
                                                : 'black',
                                            textDecorationLine: weeklyPick[`game${index + 1}`]?.isGameOver &&
                                                !weeklyPick[`game${index + 1}`].isCorrect
                                                ? 'line-through'
                                                : '',
                                            fontWeight: this.state.sort.column === `game${index + 1}` ? 'bold' : '',
                                            maxWidth: '100px',  // Limit width of game columns
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {weeklyPick[`game${index + 1}`]?.gameAndPoints}
                                    </td>
                                ))}
                                <td
                                    style={{
                                        color: 'black',
                                        fontWeight: this.state.sort.column === 'mnfScore' ? 'bold' : '',
                                        maxWidth: '80px',  // Limit width of MNF column
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {weeklyPick.mnfScore}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    async handleChange(e) {
        await this.setState({
            weekNo: e.value
        });
        this.populateAbbreviations();
        this.populateWeeklyPickData();
        this.populateWeeklyWinner();
        this.renderWeeklyPicksTable(this.state.weeklyPicks);
        this.render();
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderWeeklyPicksTable(this.state.weeklyPicks);

        return (
            <div className="formContainer">
                <div className="form">
                <h1 id="tabelLabel">Weekly Results</h1>
                <Select id="allWeekNo" value={{ value: this.state.weekNo, label: 'Week ' + this.state.weekNo }} onChange={this.handleChange.bind(this)} options={options} />
                {contents}
                </div>
            </div>
        );
    }

    async populateWeeklyPickData() {
        const response = await fetch('users/picks/' + this.state.weekNo);
        const data = await response.json();
        this.setState({ weeklyPicks: data, loading: false });
    }

    async populateCurrentWeek() {
        const response = await fetch('week/weekNo');
        const weekNo = await response.json();
        this.setState({ weekNo: weekNo });

        var weekPicker = document.getElementById('allWeekNo');
        weekPicker.value = { value: weekNo, label: 'Week ' + weekNo };
    }

    async populateWeeklyWinner() {
        const response = await fetch('week/weeklyWinner/' + this.state.weekNo);
        if (response !== null) {
            const data = await response.json();
            this.setState({ weeklyWinner: data });
        }
    }

    async populateAbbreviations() {
        const response = await fetch('week/weekGameAbbreviations/' + this.state.weekNo);
        const data = await response.json();
        this.setState({ abbreviations: data });
    }
}
