import { combineReducers } from 'redux';

const ADD_USER = 'ADD_USER';
const EDIT_PASSWORD = 'EDIT_PASSWORD';

export function addUser(user) {
    return user;
}

export function editPassword(user) {
    return {
        type: EDIT_PASSWORD,
        user
    }
}

const defaultUser = 
{
        userID: 0,
        name: null,
        email: 'null',
        password: 'empty'
    }
;

function users(state = defaultUser, action) {
    switch (action.type) {
        case ADD_USER:
            return [
                {
                    userID: state.userID,
                    name: state.name,
                    email: state.email,
                    password: state.password
                }
            ];
        case EDIT_PASSWORD:
            return [
                ...state,
                {
                    name: action.user,
                    email: 'null',
                    password: 'empty'
                }
            ];
        default:
            return state;
    }
}

const userApp = combineReducers({
    users
});

export default userApp;